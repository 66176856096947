<template>
  <div>
    <p class="text-xl text-grey-light font-medium font-headers mb-3.5">
      Companies / {{ currentCompany.name }} /
      <span class="font-bold text-grey-dark-3">Company Details</span>
    </p>
    <div class="bg-white px-2 pt-3 pb-4 rounded shadow">
      <div class="flex justify-between items-center pb-2.5">
        <p class="text-grey-light font-bold font-headers text-mdl">
          Company Details
        </p>
        <Button
          text="Save"
          type="primary"
          size="medium"
          @click.native="update"
        />
      </div>
      <div class="pl-2 w-2/5">
        <InputField
          label="Company Name"
          type="text"
          class="flex-grow mb-3"
          :onChange="(value) => (companyName = value)"
          :defaultValue="companyName"
          :isTextOk="$v.companyName.$invalid ? false : null"
          errorText="Company name is required"
        />

        <Select
          label="Industry"
          :options="options"
          :searchable="true"
          :onSelect="selectIndustry"
          :value="selectedIndustry"
          :preselectFirst="false"
          data-testid="industry"
        />
      </div>
    </div>
    <div class="bg-white px-2 pt-3 pb-4 rounded shadow mt-2">
      <p class="text-grey-light font-bold font-headers text-mdl">Status</p>
      <div class="pt-1.5 px-2">
        <p class="text-grey-dark-1 text-mdh">
          Deactivation is reversible, a company can be activated at any stage if
          needed.
        </p>
        <div class="flex mt-2 justify-between items-center">
          <p
            class="text-md"
            :class="currentCompany.active ? 'text-green' : 'text-red'"
          >
            {{ currentCompany.active ? "Active" : "Inactive" }}
          </p>
          <button
            @click="toggleStatus"
            class="text-md font-semibold"
            :class="
              currentCompany.active
                ? 'text-red'
                : 'text-sm-2 font-medium text-blue-dark-1 px-2 py-1 bg-blue-light-1 rounded'
            "
          >
            {{
              currentCompany.active ? "Deactivate Company" : "Activate Company"
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../../components/Button/Button.vue";
import InputField from "../../../components/InputField/InputField.vue";
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";

export default {
  components: { InputField, Button },
  name: "CompanyEditView",
  data: () => ({
    selectedIndustry: null,
  }),
  mounted() {
    this.selectedIndustry = {
      label: this.currentCompany.industryName,
      id: this.currentCompany.industryId,
    };
  },
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
      industries: (state) => state.companies.industries,
      industrySectors: (state) => state.companies.industrySectors,
    }),
    options() {
      return this.industries.map((el) => ({
        label: el.name,
        id: el.id,
      }));
    },
    companyName: {
      get() {
        return this.currentCompany.name;
      },
      set(val) {
        this.currentCompany.name = val;
      },
    },
    disableSave() {
      return !this.companyName || !this.selectedIndustry;
    },
  },
  validations: {
    companyName: { required },
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      edit: "companies/editCompany",
    }),
    selectIndustry(val) {
      this.selectedIndustry = val;
    },
    async update() {
      let payload = {
        name: this.companyName,
        industryId: this.selectedIndustry.id,
      };
      await this.edit({
        companyId: this.currentCompany.id,
        payload,
      });
    },
    async toggleStatus() {
      if (this.currentCompany.active) {
        this.openModal({ modal: "deactivateCompanyModal" });
      } else {
        let payload = {
          name: this.companyName,
          industryId: this.selectedIndustry.id,
          active: true,
        };
        await this.edit({
          companyId: this.currentCompany.id,
          payload,
        });
      }
    },
  },
};
</script>
